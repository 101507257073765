import * as React from 'react';
import { Module } from '@msdyn365-commerce-modules/utilities';
import { IUnbxdRecommendationsViewProps } from './unbxd-recommendations';
import classnames from 'classnames';
import MsDyn365 from '@msdyn365-commerce/core';
import { UnbxdRecommendationsOptions, UnbxdWidgetConfig } from './unbxd-recommendations.data';

const defaultRecommendationSdk = 'https://libraries.unbxdapi.com/recs-sdk/v3.1.0/unbxd_recs_template_sdk.js';
const defaultUaLib = 'https://libraries.unbxdapi.com/ua-js/v1.0.2/uaLibrary.js';

let lastId = 0;

export default (props: IUnbxdRecommendationsViewProps) => {
    const { isBrowser } = MsDyn365;
    const [initialized, setInitialized] = React.useState(false);
    const divRef = React.useRef<HTMLDivElement>(null);
    const { widgetName, className } = props.config;

    const id = React.useMemo(() => {
        return `hei-unbxd-rec-widget-${++lastId}`;
    }, []);

    const currentSku = props.data.product?.result?.ItemId ?? '';

    React.useEffect(() => {
        const div = divRef.current;
        if (isBrowser && !initialized && div && widgetName) {
            setInitialized(true);

            const widgetDiv = document.createElement('div');
            widgetDiv.id = id;
            widgetDiv.setAttribute('data-hei-unbxd-widget', widgetName);

            div.appendChild(widgetDiv);
        }
    }, [widgetName]);

    const recommendationSdk = props.context.app.config.heiUnbxdRecommendationSdk ?? defaultRecommendationSdk;
    const uaLib = props.context.app.config.heiUnbxdAutosuggestCss ?? defaultUaLib;

    React.useEffect(() => {
        const body = window.document.getElementsByTagName('body')[0];
        if (isBrowser && body) {
            if (!window.unbxdRecInit) {
                window.unbxdRecInit = true;

                if (!window.UnbxdSiteName && props.context.app.config.heiUnbxdSiteKey) {
                    window.UnbxdSiteName = props.context.app.config.heiUnbxdSiteKey;
                }

                {
                    const userId = props.context.request.cookies.get<string>('unbxd.userId').value ?? '';
                    const siteKey = props.context.app.config.heiUnbxdSiteKey;
                    const apiKey = props.context.app.config.heiUnbxdApiKey;

                    // Inject unbxdRecommendationSdk
                    const scriptTag = document.createElement('script');
                    scriptTag.async = true;
                    scriptTag.src = recommendationSdk;
                    scriptTag.onload = () => {
                        const widgets: UnbxdWidgetConfig[] = [];

                        document.querySelectorAll('[data-hei-unbxd-widget]').forEach(elem => {
                            widgets.push({
                                name: elem.getAttribute('data-hei-unbxd-widget') ?? '',
                                selector: elem.id
                            });
                        });

                        const opts: UnbxdRecommendationsOptions = {
                            widgets,
                            userInfo: {
                                userId,
                                siteKey,
                                apiKey
                            },
                            pageInfo: {
                                pageType: currentSku ? 'PRODUCT' : 'HOME',
                                productIds: currentSku ? [currentSku] : undefined
                            },
                            itemClickHandler: product => {
                                let productUrl = `/${product.RecordId}.p`;
                                try {
                                    const encodedName = product.ProductName?.replace(/\./gi, '')
                                        .replace(/'/gi, '')
                                        .replace(/&/gi, '-')
                                        .replace(/[^a-zA-Z0-9&.]/gi, '-')
                                        .replace(/[-]{2,}/g, '-')
                                        .toLowerCase();
                                    productUrl = `/${encodedName}/${product.RecordId}.p`;
                                } catch (err) {
                                    process.env.NODE_ENV === 'development' && console.log('UnbxdRecommendations', err);
                                }

                                window.location.assign(productUrl);
                                process.env.NODE_ENV === 'development' && console.log('UnbxdRecommendations', product);
                            }
                        };

                        process.env.NODE_ENV === 'development' && console.log('UnbxdRecommendations', opts);
                        window.getUnbxdRecommendations && window.getUnbxdRecommendations(opts);
                    };

                    body.appendChild(scriptTag);
                }

                {
                    // Inject unbxdUaLib
                    const scriptTag = document.createElement('script');
                    scriptTag.async = true;
                    scriptTag.src = uaLib;
                    body.appendChild(scriptTag);
                }
            }
        }
    }, [isBrowser, currentSku]);

    return <Module moduleProps={props} ref={divRef} className={classnames('hei-unbxd-rec-container', className)}></Module>;
};
